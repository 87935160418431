<template>
  <div class="vert-ensemb">
    <div class="borduretop largeAnc">Communes</div>
    <div class="topcontent largeAnc">
      <div class="champ-nom">
        <input id="saisie" type="text" :placeholder="modeStrict ? 'Nom complet' : 'Nom partiel'" autocomplete="off" v-model="nomcomm" @paste.prevent="onpaste($event)" @input="envoiNom(nomcomm)"/>
        <span class="nbrreslt">{{ nbrres }}</span>
        <span class="material-icons ferme" v-show="nomcomm.length > 0" @click="viderChamp">backspace</span>
        <span class="material-icons troiscents" v-if="nbrres == 300" @mouseover="showMsg = true" @mouseleave="showMsg = false">error_outline</span>
        <button class="small btnAncien" v-if="btnAnciennes" @click="affichAnc()">ANCIENNES</button>
        <button :class="{ actif: modeStrict }" class="small chgMode" @click="strictChg()">STRICT</button>
        <div class="autresRes" v-show="showMsg">Il peut exister d'autres résultats. Augmentez le nombre de lettres ou activez le mode strict.</div>
      </div>
    </div>
    <div class="card scroller">
      <div class="resultats" v-if="comTrouvees" v-html="chaine" @click="handleClicks($event)"></div>
      <div v-else><p class="noresult">Aucun résultat</p></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Parnom",
  props: ["nomcomm", "injectNm", "chpays"],
  data() {
    return {
      donnees: {},
      nbrres: 0,
      chaine: "",
      modeStrict: false,
      comTrouvees: false,
      btnAnciennes: false,
      showMsg: false,
      timout: null,
      parser: new DOMParser(),
    };
  },
  methods: {
    envoiNom(n) {
      if (!n) {
        this.raz();
        return;
      }
      if (/saint\W{1}|sainte\W{1}/gi.test(n)) {
        n = n.replace(/saint/gi, "st");
        this.getListeNoms(n, true);
        return;
      }
      clearTimeout(this.timout);
      this.timout = setTimeout(() => {
        if (this.mdstr || n.length > 2) this.getListeNoms(n, false);
      }, 333);
    },
    onpaste(e) {
      let r = e.clipboardData.getData("text");
      if (/saint\W{1}|sainte\W{1}/gi.test(r)) {
        r = r.replace(/saint/gi, "st");
      }
      this.getListeNoms(r.toLowerCase(), false);
    },
    affichAnc() {
      ClicAncOrign = 0; // origine du clic sur le bouton Anciennes
      ChaineRecherche = this.nomcomm;
      this.$emit("triggerAnc");
    },
    viderChamp() {
      this.raz();
      byId("saisie").focus();
    },
    async getListeNoms(n, t) {
      this.$emit("nomChg", n);
      if (t) return;
      let mds = this.modeStrict ? 1 : 0;
      let res = await this.axios.get("php/parnom.php?mot=" + n + "&pays=" + Pays + "&mode=" + mds);
      // 0: nom, 1: codep, 2: region, 3: coord, 4: alt, 5: homon, 6: dept, 7: id
      if (typeof res.data !== "string") {
        this.donnees = res.data;
        let dbl = "";
        let tmp = this.donnees[0][2];
        this.chaine =
          '<ul class="listres"><li class="nomregion">' +
          Regions[Pays][tmp] +
          "</li>";
        if (Pays == 1) {
          // France
          this.donnees.forEach((donn, index) => {
            if (donn[2] != tmp) {
              tmp = donn[2];
              this.chaine += '<li class="nomregion">' + Regions[1][tmp] + "</li>";
            }
            if (donn[5] == 1) dbl = ' class="doublon"'; else dbl = ""
            this.chaine += "<li" + dbl + ' data-ind="' + index + '">' + donn[0] + '<span class="departm">' + Departm[donn[6]] + "&nbsp;" + donn[1] +
            "</span></li>";
          });
        } else {
          // Belgique, Suisse, Québec
          this.donnees.forEach((donn, index) => {
            if (donn[2] != tmp) {
              tmp = donn[2];
              this.chaine +=
                '<li class="nomregion">' + Regions[Pays][tmp] + "</li>";
            }
            if (donn[5] == 1) dbl = ' class="doublon"';
            else dbl = "";
            this.chaine += "<li" + dbl + ' data-ind="' + index + '">' + donn[0] + '<span class="departm">' + donn[1] + "</span></li>";
          });
        }

        this.chaine += "</ul>";
        this.comTrouvees = true;
        this.nbrres = this.donnees.length;
        // Si France, regarder s'il existe des anciennes communes
        this.AfficheBtnAncien(n, mds)
      } else {
        // Commune non trouvée. Rechercher sur Wikipédia
        let mot = n.replace(/st\W/gi, "Saint-").replace(/ste\W/gi, "Sainte-");
        let reg = new RegExp("^" + mot.latinise() + "(?=$| [(])", "i");
        res = await this.axios.get("https://fr.wikipedia.org/w/api.php?action=query&generator=prefixsearch&prop=description&format=xml&origin=*&gpssearch=" + mot);
        let xmlDoc = this.parser.parseFromString(res.data, "text/xml");
        try {
          let r = xmlDoc.getElementsByTagName("pages")[0].childNodes;
          let chn = '<ul class="listres">';
          let trouv = false;
          if (r.length > 0) {
            let t = "";
            let d = "";
            r.forEach((donn) => {
              t = donn.getAttribute("title");
              d = donn.getAttribute("description");
              if (typeof d !== "undefined" && reg.test(t.latinise())) {
                if (Multloc.test(d)) {
                  d = d.charAt(0).toUpperCase() + d.slice(1);
                  if (d.length > 60) d = d.substr(0, 59) + " ...";
                  chn += '<li data-nom="' + t + '">' + d + "</li>";
                  trouv = true;
                }
              }
            });
          }

          this.nbrres = 0;
          if (trouv) {
            chn += "</ul>";
            this.chaine = chn;
            this.comTrouvees = true;
          } else {
            this.comTrouvees = false;
          }
          this.AfficheBtnAncien(n, mds)
        } catch (error) {
          this.comTrouvees = false;
        }
      }
      // axios.get("https://restcountries.eu/rest/v2/all?fields=translations").then((res) => this.pays = res.data)
    },
    handleClicks(e) {
      let cible = e.target.closest("li");
      if (cible.dataset.nom !== undefined) {
        if (cible.getElementsByClassName("oeil").length == 0)
          cible.innerHTML += '<i class="material-icons oeil">visibility</i>';
        this.$emit("wikip", cible.dataset.nom);
      } else {
        let indx = cible.dataset.ind;
        if (indx !== undefined) {
          if (cible.getElementsByClassName("oeil").length == 0)
            cible.innerHTML += '<i class="material-icons oeil">visibility</i>';
          let charge = {
            nom: this.donnees[indx][0],
            codep: this.donnees[indx][1],
            region: this.donnees[indx][2],
            coord: this.donnees[indx][3],
            alt: this.donnees[indx][4],
            homon: this.donnees[indx][5],
            dept: this.donnees[indx][6],
            id: this.donnees[indx][7]
          };
          this.$emit("mapcomm", charge);
        }
      }
    },
    async AfficheBtnAncien(n, m) {
      if (Pays == 1) {
        let res = await this.axios.get("php/ancexiste.php?nom=" + n + "&mode=" + m);
        this.btnAnciennes = res.data == 1 ? true : false;
      } else this.btnAnciennes = false
    },
    strictChg() {
      this.modeStrict = !this.modeStrict;
      if (
        (!this.modeStrict && this.nomcomm.length > 2) ||
        (this.modeStrict && this.nomcomm.length > 0)
      )
        this.getListeNoms(this.nomcomm, false);
      else this.comTrouvees = false;
    },
    raz() {
      this.$emit("nomChg", "");
      this.comTrouvees = false;
      this.btnAnciennes = false;
      this.nbrres = 0;
      if (ChaineRecherche != "" && isNaN(parseInt(ChaineRecherche))) {
        ChaineRecherche = "";
        this.$emit("fermAnci");
      }
    },
  },
  watch: {
    // Nom de commune injecté par Parcode.vue ou Anciennes.vue
    injectNm() {
      if (this.nomcomm.length > 2) this.getListeNoms(this.nomcomm, false);
    },
    chpays() {
      if (this.nomcomm.length > 2) this.getListeNoms(this.nomcomm, false);
    },
  },
};
</script>
