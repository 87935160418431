<template>
  <section>
    <div class="fond4">
      <p class="soustitre">GEOCODAGE</p>
      <button class="grand btngeoinv" @click="chggeoc">{{ btnval }}</button>
    </div>
    <div class="conteneur rech">
      <form class="boite rge" @submit.prevent="soumForme" :class="{desc: voircarte}">  <!--  @submit.prevent="soumForme" -->
        <div :class="{disab: geocodInv}">
          <label for="geovoie">Numéro & voie</label>
          <input type="text" id="geovoie" v-model="forme2.geovoie" autocomplete="off">
        </div>
        <div>
          <label for="geoville">{{ labelval }}</label>
          <div class="flecs">
            <input type="text" id="geoville" v-model="forme2.geoville" autocomplete="off"><span class="material-icons ferme" v-show="forme2.geoville != ''" @click="videVille" style="float: right; line-height: 1.4">backspace</span>  <!--  list="choixadresse" @input="envoiCode(forme2.geoville)" -->
            <!-- <datalist id="choixadresse"></datalist> -->
          </div>
        </div>
        <div class="stl3">
          <button class="grand">Envoyer</button>
        </div>
        <div class="geocodres">
          <table>
            <tbody>
              <tr>
                <td>Latitude, Longitude</td>
                <td id="copie" v-show="coord != ''">{{ coord }}<span class="material-icons btncopy" @click="copier(1)">content_copy</span></td>
              </tr>
              <tr>
                <td>Correspondance</td>
                <td>{{ corresp }}</td>
              </tr>
              <tr>
                <td>Adresse géocodée</td>
                <td>{{ adrgeocod }}</td>
              </tr>
              <tr>
                <td>Localisation</td>
                <td>{{ localis }}</td>
              </tr>
              <tr>
                <td class="crdclic">Coord. du clic</td>
                <td id="copie2" class="floroot">{{ cclic }}<span class="material-icons btncopy" v-show="cclic != ''" @click="copier(2)">content_copy</span></td>
              </tr>
            </tbody>
          </table>
          <div class="centrer" v-show="coord != ''">
            <button class="moyen" @click.prevent="revoirCarte">Voir la carte</button>
            <button class="moyen bleu" @click.prevent="googmaps">Google Maps</button>
          </div>
        </div>
        <div class="alerte" v-show="alert2"><span>{{ msg }}</span></div>
      </form>
    </div>
    <transition name="fade2">
      <div class="modal" v-show="voircarte">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <i class="material-icons close" @click="voircarte = false">cancel</i>
            </div>
            <div class="googmap">
              <GoogleMaps :dnGoogle="donGoog" :proven="'geocoder'" @clicCarte="coordClic($event)" />
              <button class="grand orange margt8" @click="voircarte = false">Fermer</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
  import GoogleMaps from '../GoogleMaps.vue'
  import Eframe from '@/components/Eframe.vue'

  export default {
    name: 'Geoloc',
    components: { GoogleMaps, Eframe },
    data() {
      return {
        forme2: {
          geovoie: '',
          geoville: ''
        },
        alert2: false,
        msg: '',
        coord: '',
        corresp: '',
        adrgeocod: '',
        localis: '',
        cclic: '',
        labelval: 'Code postal & Ville',
        btnval: 'Géocodage inverse',
        donGoog: {},
        voircarte: false,
        geocodInv: false,
        range: new Range(),
        geocoder: new google.maps.Geocoder()
      }
    },
    methods: {
      soumForme() {
        if (!this.geocodInv && this.forme2.geovoie == '') this.message("Le premier champ doit être renseigné")
        else {
          let chaine = '', arr = ["1", "2"], arr2 = [], temp
          if (this.geocodInv) {
            chaine = this.forme2.geoville
            chaine = chaine.trim()
            if (/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.test(chaine) === false) {
              this.message("Veuillez indiquer des coordonnées valides")
              return
            }
          } else {
            if (this.forme2.geoville != "") chaine = this.forme2.geovoie + "," + this.forme2.geoville; else chaine = this.forme2.geovoie
          }
   
          this.geocoder.geocode({ 'address': chaine }, (results, status) => {
            if (status === 'OK') {
              temp = results[0].address_components
              temp.forEach((donn, index) => {
                if (donn.types[0] == "administrative_area_level_2") arr[1] = donn.long_name
                if (donn.types[0] == "administrative_area_level_1") arr[0] = donn.long_name
              })
              this.coord = this.arrondir(results[0].geometry.location.lat()) + ", " + this.arrondir(results[0].geometry.location.lng())
              let g = results[0].geometry.location_type
              if (g == "ROOFTOP") this.corresp = "exacte";
              else if (this.geocodInv) {
                if (g == "RANGE_INTERPOLATED") this.corresp = "interpolée";
                else if (g == "GEOMETRIC_CENTER") this.corresp = "centre géométrique";
                else this.corresp = "approximative"
              } else {
                this.corresp = "partielle"
              }
              temp = results[0].formatted_address
              temp = temp.replace("Unnamed Road", "Route inconnue")
              arr2 = temp.split(",")
              arr.unshift(arr2.pop())
              if (arr[2] == "2") arr.pop()
              if (arr[1] == "1") arr.pop()
              this.adrgeocod = arr2.join(", ")
              this.localis = arr.join(", ")
              this.cclic = ''
            } else {
              if (status == "ZERO_RESULTS") this.message("Aucune donnée n'est disponible");
              else if (status == "OVER_QUERY_LIMIT") {
                let pa = new Date()
                let pah = pa.getHours() // Heure de Paris
                let la = pa.toLocaleTimeString("fr-FR", {timeZone: "America/Los_Angeles"})
                let lah = parseInt(la.split(':')[0])  // Heure de Los Angeles
                let d = pah - lah // différence d'heure Paris - Los Angeles
                if (d < 0) { d = 24 + d; chaine = "à " } else chaine = "demain à "
                chaine += d + " h (heure française)"
                this.message("Requêtes trop nombreuses ces dernières 24h. Veuillez réessayer " + chaine)
              }
              else if (status == "INVALID_REQUEST") this.message("Les informations que vous avez fournies sont incomplètes");
              else this.message("Une erreur inattendue s'est produite. Veuillez réessayer ultérieurement")
              this.raz()
            }
          })
        }
      },
      googmaps() {
        window.open('https://www.google.com/maps/search/?api=1&zoom=9&query=' + this.coord, '_blank').focus()
      },
      raz() {
        this.coord = this.corresp = this.adrgeocod = this.localis = this.cclic = ''
      },
      arrondir(n) {
        return Number(Math.round(n + 'e' + 6) + 'e-' + 6);
      },
      videVille() {
        this.forme2.geoville = ''
      },
      revoirCarte() {
        this.donGoog = {coord: this.coord}
        this.voircarte = true
        this.descend = true
      },
      message(txt) {
        this.msg = txt
        this.alert2 = true
        setTimeout(() => {
          this.alert2 = false
        }, 5000);
      },
      chggeoc() {
        this.geocodInv = !this.geocodInv
        if (this.geocodInv) {
          this.forme2.geovoie = this.forme2.geoville = ''
          this.labelval = 'Coordonnées géographiques (lat,lng)'
          this.btnval = 'Géocodage'
        } else {
          this.forme2.geoville = ''
          this.labelval = 'Code postal & Ville'
          this.btnval = 'Géocodage inverse'
        }
        this.raz()
      },
      copier(n) {
        let elem = null
        document.getSelection().removeAllRanges()
        if (n == 1) elem = byId('copie');
        else elem = byId('copie2')
        this.range.setStart(elem, 0)
        this.range.setEnd(elem, 1)
        document.getSelection().addRange(this.range)
        document.execCommand('copy')
        setTimeout(() => {
          document.getSelection().removeAllRanges()
        }, 100)
      },
      coordClic(e) {
        if (e.pixel !== undefined) this.cclic = this.arrondir(e.latLng.lat()) + ", " + this.arrondir(e.latLng.lng())
      }
      // envoiCode(n) {
      //   if (n.length == Ncar) {
          
      //   }
      // }
      // 	$('#geoville').keyup(function () {
      //   if ($(this).val().length == ncar) {
      //     $.post("php/geocodCP.php", { "cp": $(this).val(), "pays": pays }, function (data) {
      //       var k, chaine = "";
      //       choixadresse.empty();
      //       if (data !== "pastrouve") {
      //         k = JSON.parse(data);
      //         $.each(k, function (cle, valeur) {
      //           spl = valeur.split("|");
      //           chaine += '<option value="' + spl[0] + ' ' + spl[1] + '">'
      //           // chaine += '<option value="' + spl[1] + '">'
      //         });
      //         choixadresse.html(chaine).show();
      //       }
      //     });
      //   }
      // })
    }
  }
</script>
